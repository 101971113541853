<template>
  <v-card flat>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-stepper
      v-model="e1"
      class="ma-5"
    >
      <v-stepper-header class="full-height-class no-flex-wrap">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          dark
          color="color_green"
        >
          Summary applied policy
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
          dark
          color="color_green"
        >
          Select app users to assign/remove policy
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="e1 > 3"
          step="3"
          dark
          color="color_green"
        >
          Select Application and Policy
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-data-table
            id="app-user-table"
            :search="searchUser"
            :headers="services.appUserLinkHeaders"
            :items="app_user.aUsers"
            class="elevation-2 ma-1"
            @click:row="managePolicies($event)"
          >
            <template v-slot:no-data>
              <span color="primary"> No app users yet. Add some! </span>
            </template>
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
                class="dataTableTop"
              >
                <span class="title text-color-green">Summary users policies</span>
                <v-spacer />
                <v-text-field
                  v-model="searchUser"
                  append-icon="mdi-magnify"
                  label="Search User"
                  dense
                  single-line
                  hide-details
                />
                <v-spacer />
              </v-toolbar>
            </template>
          </v-data-table>
          <v-row class="pa-5 d-flex justify-end">
            <v-btn
              color="color_green"
              class="ma-2"
              outlined
              @click="e1 = 2; linkAction=false;"
            >
              Unlink
            </v-btn>
            <v-btn
              dark
              class="ma-2"
              color="color_green"
              @click="e1 = 2; linkAction=true"
            >
              Link
            </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-data-table
            v-model="selectedAppUser"
            :search="searchUser"
            :headers="headersAppUser"
            :items="app_user.aUsers"
            class="elevation-2 ma-1"
            :single-select="false"
            item-key="app_user_id"
            show-select
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
                class="dataTableTop"
              >
                <span class="title text-color-green">
                  {{ linkAction ? 'Link policy' : 'Unlink Policy' }}
                </span>
                <v-spacer />
                <v-text-field
                  v-model="searchUser"
                  append-icon="mdi-magnify"
                  label="Search User"
                  dense
                  single-line
                  hide-details
                />
                <v-spacer />
              </v-toolbar>
            </template>
          </v-data-table>
          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="e1 = 1"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="primary"
                :disabled="checkForm(2)"
                @click="goToStep3()"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-data-table
            v-model="selectedService"
            :search="searchApplication"
            :headers="services.headers"
            :items="getListOfServices()"
            class="elevation-2 ma-1"
            item-key="service_id"
            show-select
            @item-selected="linkAction ? setApplicationPolicies($event.item,$event.value) : null"
            @toggle-select-all="linkAction ? $event.items.map(item => setApplicationPolicies(item,$event.value)) : null"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
                class="dataTableTop"
              >
                <span class="title text-color-green">Select services and policy</span>
                <v-spacer />
                <v-text-field
                  v-model="searchApplication"
                  append-icon="mdi-magnify"
                  label="Search Application"
                  dense
                  single-line
                  hide-details
                />
                <v-spacer />
              </v-toolbar>
            </template>
            <template v-slot:[`item.application_policy`]="app">
              <v-autocomplete
                v-if="linkAction"
                v-model="app.item.selected_policy"
                :disabled="app.item.application_policy.length == 0"
                :items="app.item.application_policy"
                :menu-props="{ maxHeight: 210}"
                :label="app.item.application_policy.length != 0 ? 'Select Policy' : '...'"
                :loading="app.item.isLoading"
                class="mt-6"
                item-text="policy_name"
                return-object
                auto-select-first
                solo
                dense
              />
            </template>
          </v-data-table>
          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="e1 = 2"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="color_green"
                dark
                :disabled="checkForm(3)"
                @click="linkAction ? setPolicyPerUser() : unlinkPolicyPerUser()"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <!--
        <v-stepper-content step="4">
          <v-data-table
            v-model="selectedPolicy"
            :headers="policy.headers"
            :items="policy.activePolicies"
            class="elevation-0"
            :single-select="true"
            item-key="policy_id"
            show-select
          />
          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn @click="e1 = 3">
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="primary"
                :disabled="checkForm(4)"
                @click="setPolicyPerUser()"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        -->
      </v-stepper-items>
    </v-stepper>
    <AppPolicyManagement
      :dialog="appPolicyManagementDialog"
      :item="appPolicyManagementItem"
      :full="fullAUsers"
      @closeDialog="closeManagePolicies"
      @highlight-default-service-column="$emit('highlight-default-service-column')"
    />
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    AppPolicyManagement: () =>
      import('@/components/customer/app-user/AppPolicyManagement.vue')
  },
  props: {
    crmId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      overlay: false,
      isLoadingAutocomplete: false,
      searchApplication: '',
      searchUser: '',
      searchPolicy: '',
      e1: 1,
      linkAction: false,
      selectedAppUser: [],
      selectedService: [],
      selectedPolicy: [],
      headersAppUser: [],
      fullAUsers: [],
      appPolicyManagementDialog: false,
      appPolicyManagementItem: {
        _name: '',
        surname: '',
        email: ''
      }
    };
  },
  computed: {
    ...mapState(['services', 'app_user', 'policy'])
  },
  async beforeMount() {
    this.headersAppUser = this.getHeaders();
  },
  methods: {
    ...mapActions(['unlinkUserPolicyAction', 'storeAppUsers', 'linkUserPoliciesAction', 'storeAppPolicies', 'setSelectPolicies', 'storeLinkedServices']),
    ...mapGetters(['getHeaders']),
    checkForm(step) {
      switch (step) {
        case 2: {
          if (this.selectedAppUser.length > 0) {
            return false;
          } else {
            return true;
          }
        }

        case 3: {
          if (!this.linkAction) {
            return !(this.selectedService.length > 0 && this.selectedAppUser.length > 0);
          } else {
            return !(
              this.selectedService.length > 0 &&
              this.selectedAppUser.length > 0 &&
              this.linkAction && this.selectedService.every(service =>
                service.selected_policy && Object.keys(service.selected_policy).length > 0
              )
            );
          }
        }

        default:
          return false;
      }
    },
    async goToStep3() {
      this.overlay = true;
      this.e1 = 3;
      this.overlay = false;
    },
    async setPolicyPerUser() {
      this.overlay = true;
      const arrForCall = this.selectedService.flatMap(service => {
        return this.selectedAppUser.map(e => ({
          app_user_id: e.app_user_id,
          service_id: service.service_id,
          policy_id: service.selected_policy.policy_id
        }));
      });
      await this.linkUserPoliciesAction(arrForCall);
      await this.storeAppUsers(this.crmId);
      await this.setSelectPolicies();
      await this.storeLinkedServices(this.crmId);
      this.$emit('highlight-default-service-column');
      this.overlay = false;
      this.e1 = 1;
      this.selectedAppUser = [];
      this.selectedService = [];
    },
    managePolicies(item) {
      this.appPolicyManagementDialog = false;
      this.appPolicyManagementItem = item;
      this.fullAUsers = this.app_user.listLinkedAppUserPolicy.filter(
        e => e.app_user_id == item.app_user_id
      );
      this.appPolicyManagementDialog = true;
    },
    closeManagePolicies() {
      this.appPolicyManagementItem = {
        _name: '',
        surname: '',
        email: ''
      };
      this.appPolicyManagementDialog = false;
    },
    async setApplicationPolicies(selectedApp, isSelected) {
      if (isSelected) {
        selectedApp.isLoading = true;
        await this.storeAppPolicies(selectedApp.service_id);
        selectedApp.application_policy = this.policy.activePolicies;
        selectedApp.selected_policy = this.setDefaultPolicy(selectedApp.application_policy);
        selectedApp.isLoading = false;
      } else {
        // When de-select the row
        selectedApp.application_policy = [];
        selectedApp.selected_policy = {};
      }
    },
    setDefaultPolicy(applicationPolicies) {
      if (applicationPolicies.length == 1) {
        return applicationPolicies[0];
      } else {
        const customer_id = this.crmId.split('id_')[1];
        const customerPolicy = applicationPolicies.find(policy => policy.policy_name.includes(customer_id));
        const generalPolicy = applicationPolicies.find(policy => policy.policy_name.includes('general'));

        if (customerPolicy) {
          return customerPolicy;
        } else if (generalPolicy) {
          return generalPolicy;
        } else {
          return applicationPolicies[0];
        }
      }
    },
    async unlinkPolicyPerUser() {
      this.overlay = true;
      const arrForCall = this.selectedService.flatMap(service => {
        return this.selectedAppUser.map(user => {
          const matchedPolicy = this.app_user.listLinkedAppUserPolicy.find(policy =>
            policy.app_user_id === user.app_user_id &&
            policy.service_id === service.service_id
          );

          if (matchedPolicy) {
            return {
              app_user_id: user.app_user_id,
              service_id: service.service_id,
              policy_id: matchedPolicy.policy_id
            };
          }
        }).filter(Boolean);
      });
      await this.unlinkUserPolicyAction(arrForCall);
      await this.storeAppUsers(this.crmId);
      await this.setSelectPolicies();
      await this.storeLinkedServices(this.crmId);
      this.$emit('highlight-default-service-column');
      this.overlay = false;
      this.e1 = 1;
      this.selectedAppUser = [];
      this.selectedService = [];
    },
    getListOfServices() {
      if (this.linkAction) {
        return this.services.linkedServices.filter(service => service.service_type !== 'TICKET');
      }

      return this.services.linkedServices.filter(service => {
        return this.selectedAppUser.some(user => {
          return user[service.service_id];
        });
      });
    }
  }
};
</script>

<style scoped>
.full-height-class {
  height: auto;
}
.no-flex-wrap {
  flex-wrap: none;
}
.elevation-1 >>> tbody tr:hover {
  cursor: pointer;
}
.dataTableTop {
  padding: 8px 0px;
  height: auto!important;
  border-bottom: 1px solid lightgray !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.dataTableTop.v-toolbar__content {
  width: 100%;
}
.text-color-green {
  color: #0ca344;
}
</style>
